import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/TeamMemberLayout.tsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Tyler joined CLHbid.com team in the role of Director of Sales in 2021. His roots are in rural Alberta and he has had a 20-year career in the auction business serving customers worldwide.`}</p>
    <p>{`With growing up in the auction world, he has collected a lot of experience along the way. He started his career at a young age in the Peace Country with a focus on the livestock sector and then he transitioned into the equipment auction business in 2003. With a strong agricultural foundation, Tyler helped build the North American farm sale business into what it is today. He has done all parts of the sale process including management, sales, and he is an International Auctioneer.`}</p>
    <p>{`Tyler is known for his drive and commitment to finding creative solutions for customers. He has years of experience supporting customers making major life changing decisions. His commitment extends beyond signing the deal and he is there to support the customer for every step of the process.`}</p>
    <p>{`Tyler and his family recently moved to Ponoka County to be in the heart of Cowboy Country. Tyler knows that community is the backbone of the farm business and has helped with charitable events coast to coast. For Tyler, it’s about getting up every day and doing the right thing.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      